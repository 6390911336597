import React, { useState } from 'react'
import bg from '../../../assets/icons/newsletter.png';
import Button from '../Button/Button';

const NewsLetter = ({ handleFun }) => {

    const [email, setEmail] = useState('')

    const addEmail = () => {
        if(email === ''){

            alert('Email can\'t be empty, Please add a valid email address')
            return
        }
        
        const url = new URL(
            "https://api.sender.net/v2/subscribers"
        );

        let headers = {
            "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzcwOGVmMTg3YWNhZmJlNjhkNmQ1YzQ3ZWMwYzc4ZGVjNDY2NWNhZjEwNTY5OWM3YzNlYWFjNzgxOGQyY2RiYjQ4MDE2NjVlOGY5ODE0MjAiLCJpYXQiOiIxNzIwNTExNzk2LjQ1MTQ2NiIsIm5iZiI6IjE3MjA1MTE3OTYuNDUxNDcwIiwiZXhwIjoiNDg3NDExMTc5Ni40NDkzOTEiLCJzdWIiOiI4ODUxMDkiLCJzY29wZXMiOltdfQ.N07hzgPsQjhe92lLOPM51W2GKjwsUodXZ2mV4kDaayCHeo1tNIdYRwYbDVc-gKh4RWG6Y8bPO3AZfBz0ho78HA",
            "Content-Type": "application/json",
            "Accept": "application/json",
        };

        let data = {
            "email": email,
            "groups": ["av9qv5"],
            "trigger_automation": false
        };

        fetch(url, {
            method: "POST",
            headers, body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((res) => {
                console.log(res)
                if (!res.success) {
                    alert('An error occured')
                    return
                }

                alert('You have subscribed successfuly. Thank you')
                handleFun()
            });
    }


    return (
        <div className='h-full w-full flex flex-col fixed z-50 justify-center items-center'>
            <div className='h-full w-full bg-black fixed bg-opacity-80' onClick={handleFun} />

            <div className='w-[90%] flex md:w-[70%] rounded-md relative'>
                <img src={bg} alt='' />

                <div className=' z-20 text-[#0050AE] text-sm text-center flex justify-center items-center font-bold absolute top-[3.5rem] md:text-lg left-2 md:top-40 md:left-5 bg-white rounded-full md:w-6 md:h-6 w-4 h-4 cursor-pointer' onClick={handleFun}>
                    {/* <Button btnText={'X'} btnColor={"#0050AE"} handleClick={handleFun}/> */}
                    X
                </div>

                <div className='p-2 md:p-10 absolute w-[80%] md:w-[60%] top-[2.7rem] md:top-40 text-center'>
                    <h1 className='text-white text-md font-bold text-center md:text-5xl'>Don’t Miss Anything.</h1>
                    <p className='text-sm md:text-lg text-white md:pt-6 w-[90%] leading-none'>Be the first to get notified about updates, promotions and many more.</p>

                    <div className='w-full flex md:mt-8 mt-1'>
                        <input placeholder='Email Address' className='w-[30%] p-0 md:p-1 md:w-[60%] rounded-md' onChange={(e) => setEmail(e.target.value)} value={email} />
                        <div className='ml-1 md:ml-4'>
                            <Button btnText={'Let\'s Go'} btnColor={"#0050AE"} btnBgColor={'#fff'} btnRadius={5} btnPadding={6} btnPaddingLeft={50} btnPaddingRight={50} handleClick={addEmail} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NewsLetter